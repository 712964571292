import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CreateProjectTaskRequest,
  CreateProjectTaskResult,
  DeleteProjectTaskResult,
  ProjectTaskDto,
  ProjectTaskResult,
  ProjectTasksResult,
  UpdateProjectTaskRequest,
} from '@models/task';

@Injectable({
  providedIn: 'root',
})
export class TaskApiService {
  baseUrl = `${environment.baseUrl}/api/project-tasks`;

  constructor(private http: HttpClient) {}

  getTasks(
    beforeTs?: string,
    afterTs?: string,
    projectIds?: number[],
  ): Observable<ProjectTaskDto[]> {
    const params: any = {};
    if (beforeTs) {
      params['beforeTs'] = beforeTs;
    }
    if (afterTs) {
      params['afterTs'] = afterTs;
    }
    if (projectIds) {
      params['projectId'] = projectIds;
    }
    return this.http
      .get<ProjectTasksResult>(this.baseUrl, { params })
      .pipe(map((resp) => resp?.projectTasks || []));
  }

  getTask(id: string): Observable<ProjectTaskDto> {
    return this.http
      .get<ProjectTaskResult>(`${this.baseUrl}/${id}`)
      .pipe(map((resp) => resp?.projectTask));
  }

  updateTask(
    id: string,
    body: UpdateProjectTaskRequest,
  ): Observable<ProjectTaskDto> {
    return this.http
      .patch<ProjectTaskResult>(`${this.baseUrl}/${id}`, body)
      .pipe(map((resp) => resp?.projectTask));
  }

  createTask(body: CreateProjectTaskRequest): Observable<ProjectTaskDto> {
    return this.http
      .post<CreateProjectTaskResult>(this.baseUrl, body)
      .pipe(map((resp) => resp?.projectTask));
  }

  deleteTask(id: number): Observable<DeleteProjectTaskResult> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
