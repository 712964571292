import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { first, map, Observable } from 'rxjs';
import { GetEmployeeByIdResult } from '@models/getEmployeeByIdResult';
import { GetEmployeesResult } from '@models/getEmployeesResult';
import { environment } from '../../../environments/environment';
import { EmployeeDto, GetEmployeesRequest } from '@models/employee';
import {
  UpdateEmployeeFullTimeScheduleRequest,
  UpdateEmployeeShiftScheduleRequest,
  UpdateReservedTimeRequest,
} from '@models/employees-schedule';

@Injectable({ providedIn: 'root' })
export class EmployeeApiService {
  private basePath = `${environment.baseUrl}/api/employees`;

  constructor(private httpClient: HttpClient) {}

  public getCurrentEmployee(): Observable<GetEmployeeByIdResult> {
    return this.httpClient.get<GetEmployeeByIdResult>(
      `${this.basePath}/current`,
    );
  }

  public getEmployeeById(id: string): Observable<EmployeeDto | null> {
    return this.httpClient
      .get<GetEmployeeByIdResult>(`${this.basePath}/${id}`)
      .pipe(
        first(),
        map((result) => result?.employee || null),
      );
  }

  public getEmployees({
    limit,
    page,
    sort,
  }: GetEmployeesRequest): Observable<GetEmployeesResult> {
    const params: HttpParams = new HttpParams();

    if (limit) {
      params.set('limit', limit);
    }
    if (typeof page === 'number') {
      params.set('page', page);
    }
    if (sort) {
      params.set('sort', sort);
    }

    return this.httpClient
      .get<GetEmployeesResult>(this.basePath, { params })
      .pipe(first());
  }

  updateEmployeeSchedule(
    id: number,
    body:
      | UpdateEmployeeFullTimeScheduleRequest
      | UpdateEmployeeShiftScheduleRequest,
  ): Observable<any> {
    return this.httpClient.put(`${this.basePath}/${id}/schedule`, body);
  }

  loadAvatar(id: string, body: any): Observable<any> {
    return this.httpClient
      .post(`${this.basePath}/${id}/avatar`, body)
      .pipe(first());
  }

  updateReservedTime(
    id: string,
    body: UpdateReservedTimeRequest,
  ): Observable<any> {
    return this.httpClient
      .patch(`${this.basePath}/${id}/reservedTime`, body)
      .pipe(first());
  }
}
