import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  CopyProjectRequest,
  CopyProjectResult,
  CreateProjectRequest,
  CreateProjectResult,
  DeleteProjectResult,
  GetProjectsRequest,
  PatchProjectRequest,
  PatchProjectResult,
  ProjectDto,
  ProjectResult,
  ProjectsResult,
} from '@models/project';

@Injectable({ providedIn: 'root' })
export class ProjectApiService {
  baseUrl = `${environment.baseUrl}/api/projects`;

  constructor(private http: HttpClient) {}

  getProjects(params?: GetProjectsRequest): Observable<ProjectDto[]> {
    let httpParams = new HttpParams();

    if (params?.page) {
      httpParams = httpParams.set('page', params.page);
    }
    if (params?.limit) {
      httpParams = httpParams.set('limit', params.limit);
    }
    if (params?.sort) {
      httpParams = httpParams.set('sort', params.sort);
    }

    return this.http
      .get<ProjectsResult>(this.baseUrl, { params: httpParams })
      .pipe(map((resp) => resp?.projects || []));
  }

  getProject(id: string): Observable<ProjectDto> {
    return this.http
      .get<ProjectResult>(`${this.baseUrl}/${id}`)
      .pipe(map((resp) => resp?.project));
  }

  updateProject(id: number, body: PatchProjectRequest): Observable<ProjectDto> {
    return this.http
      .patch<PatchProjectResult>(`${this.baseUrl}/${id}`, body)
      .pipe(map((resp) => resp?.project));
  }

  createProject(body: CreateProjectRequest): Observable<ProjectDto> {
    return this.http
      .post<CreateProjectResult>(this.baseUrl, body)
      .pipe(map((res) => res?.project));
  }

  copyProject(id: number, body: CopyProjectRequest): Observable<ProjectDto> {
    return this.http
      .post<CopyProjectResult>(`${this.baseUrl}/${id}/copy`, body)
      .pipe(map((resp) => resp?.project));
  }

  deleteProject(id: number): Observable<DeleteProjectResult> {
    return this.http.delete<DeleteProjectResult>(`${this.baseUrl}/${id}`);
  }
}
