import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { first, map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AddGroupMemberProps,
  CreateGroupProps,
  DeleteGroupResult,
  GetGroupMembers,
  GetGroupResult,
  GetGroupsResult,
  GroupDto,
  GroupMemberDto,
} from '@models/group-api';

@Injectable({ providedIn: 'root' })
export class GroupApiService {
  private basePath = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  getGroup(id: number): Observable<GroupDto[]> {
    return this.httpClient
      .get<GetGroupsResult>(`${this.basePath}/api/employees/${id}/groups`)
      .pipe(
        first(),
        map((resp) => resp.groups),
      );
  }

  getMyGroup(): Observable<GroupDto[]> {
    return this.httpClient
      .get<GetGroupsResult>(`${this.basePath}/api/employees/current/groups`)
      .pipe(
        first(),
        map((resp) => resp.groups),
      );
  }

  getGroups(): Observable<GroupDto[]> {
    return this.httpClient
      .get<GetGroupsResult>(`${this.basePath}/api/groups`)
      .pipe(
        first(),
        map((resp) => resp.groups),
      );
  }

  getGroupById(id: number): Observable<GroupDto> {
    return this.httpClient
      .get<GetGroupResult>(`${this.basePath}/api/groups/${id}`)
      .pipe(
        first(),
        map((resp) => resp.group),
      );
  }

  createGroup(body: CreateGroupProps): Observable<unknown> {
    return this.httpClient
      .post(`${this.basePath}/api/groups`, body)
      .pipe(first());
  }

  removeGroup(id: number): Observable<DeleteGroupResult> {
    return this.httpClient
      .delete(`${this.basePath}/api/groups/${id}`)
      .pipe(first());
  }

  getGroupMembers(id: number): Observable<GroupMemberDto[]> {
    return this.httpClient
      .get<GetGroupMembers>(`${this.basePath}/api/groups/${id}/members`)
      .pipe(
        first(),
        map((resp) => resp.members),
      );
  }

  addGroupMember(id: number, body: AddGroupMemberProps): Observable<unknown> {
    return this.httpClient
      .post(`${this.basePath}/api/groups/${id}/members`, body)
      .pipe(first());
  }

  removeGroupMember(groupId: number, employeeId: string): Observable<unknown> {
    return this.httpClient
      .delete(`${this.basePath}/api/groups/${groupId}/members`, {
        params: new HttpParams({ fromObject: { employeeId } }),
      })
      .pipe(first());
  }
}
