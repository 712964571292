import { Injectable } from '@angular/core';
import { BehaviorSubject, first, Observable, tap } from 'rxjs';
import { EmployeeDto, GetEmployeesRequest } from '@models/employee';
import {
  UpdateEmployeeFullTimeScheduleRequest,
  UpdateEmployeeShiftScheduleRequest,
  UpdateReservedTimeRequest,
} from '@models/employees-schedule';
import { EmployeeApiService } from './api';
import { GetEmployeesResult } from '@models/getEmployeesResult';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  employee$: BehaviorSubject<EmployeeDto | null> =
    new BehaviorSubject<EmployeeDto | null>(null);

  constructor(private employeeApiService: EmployeeApiService) {
    this.getCurrentEmployee();
  }

  private getCurrentEmployee(): void {
    this.employeeApiService
      .getCurrentEmployee()
      .pipe(
        tap((resp) => {
          if (resp?.employee) {
            this.setEmployee(resp.employee);
          }
        }),
        first(),
      )
      .subscribe();
  }

  setEmployee(employee: EmployeeDto) {
    this.employee$.next(employee);
  }

  getEmployees(
    params: GetEmployeesRequest = {},
  ): Observable<GetEmployeesResult> {
    return this.employeeApiService.getEmployees(params);
  }

  updateEmployeeSchedule(
    employeeId: number,
    body:
      | UpdateEmployeeFullTimeScheduleRequest
      | UpdateEmployeeShiftScheduleRequest,
  ): Observable<any> {
    return this.employeeApiService.updateEmployeeSchedule(employeeId, body);
  }

  getEmployee(id: string): Observable<EmployeeDto | null> {
    return this.employeeApiService.getEmployeeById(id);
  }

  loadAvatar(id: string, body: any): Observable<any> {
    return this.employeeApiService.loadAvatar(id, body);
  }

  updateReservedTime(
    id: string,
    body: UpdateReservedTimeRequest,
  ): Observable<any> {
    return this.employeeApiService.updateReservedTime(id, body);
  }
}
