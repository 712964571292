import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AddTaskMemberRequest,
  RemoveTaskMemberRequest,
  TaskMemberDto,
  TaskMemberResult,
  TaskMembersResult,
} from '@models/task-member';

@Injectable({
  providedIn: 'root',
})
export class TaskMemberApiService {
  baseUrl = `${environment.baseUrl}/api/project-tasks`;

  constructor(private http: HttpClient) {}

  getTaskMembers(id: string): Observable<TaskMemberDto[]> {
    return this.http
      .get<TaskMembersResult>(`${this.baseUrl}/${id}/members`)
      .pipe(map((resp) => resp?.members));
  }

  addTaskMember(
    id: string,
    body: AddTaskMemberRequest,
  ): Observable<TaskMemberDto> {
    return this.http
      .post<TaskMemberResult>(`${this.baseUrl}/${id}/members`, body)
      .pipe(map((resp) => resp?.member));
  }

  removeTaskMember(id: string, body: RemoveTaskMemberRequest): Observable<any> {
    return this.http.delete<TaskMemberResult>(`${this.baseUrl}/${id}/members`, {
      body,
    });
  }
}
