import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, Observable } from 'rxjs';
import {
  CreateProjectTaskEventRequest,
  DeleteTaskEventResult,
  ProjectTaskEventDto,
  ProjectTaskEventResult,
  ProjectTaskEventsResult,
  UpdateProjectTaskEventRequest,
} from '@models/task-event';

@Injectable({
  providedIn: 'root',
})
export class TaskEventApiService {
  baseUrl = `${environment.baseUrl}/api/events/project-task`;

  constructor(private http: HttpClient) {}

  getTaskEvents(projectTaskIds?: number[]): Observable<ProjectTaskEventDto[]> {
    const params: any = {};
    if (projectTaskIds) {
      params['projectTaskId'] = projectTaskIds;
    }

    return this.http
      .get<ProjectTaskEventsResult>(this.baseUrl, { params })
      .pipe(map((resp) => resp?.events || []));
  }

  getTaskEvent(id: string): Observable<ProjectTaskEventDto> {
    return this.http
      .get<ProjectTaskEventResult>(`${this.baseUrl}/${id}`)
      .pipe(map((resp) => resp?.event));
  }

  updateTaskEvent(
    id: string,
    body: UpdateProjectTaskEventRequest,
  ): Observable<ProjectTaskEventDto> {
    return this.http
      .patch<ProjectTaskEventResult>(`${this.baseUrl}/${id}`, body)
      .pipe(map((resp) => resp?.event));
  }

  createTaskEvent(
    body: CreateProjectTaskEventRequest,
  ): Observable<ProjectTaskEventDto> {
    return this.http
      .post<ProjectTaskEventResult>(this.baseUrl, body)
      .pipe(map((resp) => resp?.event));
  }

  deleteTaskEvent(id: string): Observable<DeleteTaskEventResult> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
