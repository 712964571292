import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { first, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CreateHolidayEventRequest } from '@models/createHolidayEventRequest';
import { CreateHolidayEventResult } from '@models/createHolidayEventResult';
import { DeleteHolidayEventByIdResult } from '@models/deleteHolidayEventByIdResult';
import { GetHolidayEventByIdResult } from '@models/getHolidayEventByIdResult';
import { GetHolidayEventsResult } from '@models/getHolidayEventsResult';
import { PatchHolidayEventRequest } from '@models/patchHolidayEventRequest';
import { PatchHolidayEventResult } from '@models/patchHolidayEventResult';
import { GetHolidaysRequest } from '@models/holiday-event';

@Injectable({ providedIn: 'root' })
export class HolidayEventApiService {
  protected basePath = `${environment.baseUrl}/api/events/holiday`;

  constructor(private httpClient: HttpClient) {}

  public addHoliday(
    body: CreateHolidayEventRequest,
  ): Observable<CreateHolidayEventResult> {
    return this.httpClient.post(this.basePath, body).pipe(first());
  }

  public deleteHoliday(id: number): Observable<DeleteHolidayEventByIdResult> {
    return this.httpClient
      .delete<DeleteHolidayEventByIdResult>(
        `${this.basePath}/${encodeURIComponent(String(id))}`,
      )
      .pipe(first());
  }

  public getHolidayById(id: number): Observable<GetHolidayEventByIdResult> {
    return this.httpClient
      .get<GetHolidayEventByIdResult>(
        `${this.basePath}/${encodeURIComponent(String(id))}`,
      )
      .pipe(first());
  }

  public getHolidays(
    params?: GetHolidaysRequest,
  ): Observable<GetHolidayEventsResult> {
    let httpParams = new HttpParams();

    if (params?.page) {
      httpParams = httpParams.set('page', params.page);
    }
    if (params?.limit) {
      httpParams = httpParams.set('limit', params.limit);
    }
    if (params?.sort) {
      httpParams = httpParams.set('sort', params.sort);
    }
    if (params?.beforeTs) {
      httpParams = httpParams.set('beforeTs', params.beforeTs);
    }
    if (params?.afterTs) {
      httpParams = httpParams.set('afterTs', params.afterTs);
    }
    if (params?.name) {
      httpParams = httpParams.set('name', params.name);
    }
    if (params?.description) {
      httpParams = httpParams.set('description', params.description);
    }

    return this.httpClient
      .get<GetHolidayEventsResult>(this.basePath, { params: httpParams })
      .pipe(first());
  }

  public updateHoliday(
    body: PatchHolidayEventRequest,
    id: number,
  ): Observable<PatchHolidayEventResult> {
    return this.httpClient
      .patch<PatchHolidayEventResult>(
        `${this.basePath}/${encodeURIComponent(String(id))}`,
        body,
      )
      .pipe(first());
  }
}
