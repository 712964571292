import { PatchPersonalEventResult } from './../../models/personal-event';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { first, Observable } from 'rxjs';

import {
  CreatePersonalEventRequest,
  GetPersonalEventRequest,
  PatchPersonalEventRequest,
} from '@models/personal-event-request';
import { CreatePersonalEventResult } from '@models/createPersonalEventResult';
import { GetPersonalEventByIdResult } from '@models/getPersonalEventByIdResult';
import { GetPersonalEventsResult } from '@models/getPersonalEventsResult';
import { environment } from '../../../environments/environment';
import { DeletePersonalEventByIdResult } from '@models/deletePersonalEventByIdResult';

@Injectable({ providedIn: 'root' })
export class PersonalEventApiService {
  private basePath = `${environment.baseUrl}/api/events/personal`;

  constructor(protected httpClient: HttpClient) {}

  public createEvent(
    body: CreatePersonalEventRequest,
  ): Observable<CreatePersonalEventResult> {
    return this.httpClient
      .post<CreatePersonalEventResult>(this.basePath, body)
      .pipe(first());
  }

  public deleteEvent(
    id: number,
    isDeleteAll?: boolean,
  ): Observable<DeletePersonalEventByIdResult> {
    const params: Record<string, boolean> = {};
    if (isDeleteAll) {
      params['isDeleteAll'] = isDeleteAll;
    }

    return this.httpClient.delete<DeletePersonalEventByIdResult>(
      `${this.basePath}/${id}`,
      { params },
    );
  }

  public getEventById(id: number): Observable<GetPersonalEventByIdResult> {
    return this.httpClient
      .get<GetPersonalEventByIdResult>(`${this.basePath}/${id}`)
      .pipe(first());
  }

  public getEvents({
    assignedUserId,
    name,
    description,
    afterTs,
    beforeTs,
    limit,
    page,
    sort,
  }: GetPersonalEventRequest): Observable<GetPersonalEventsResult> {
    let params = new HttpParams()
      .set('limit', limit)
      .set('page', page)
      .set('sort', sort);

    if (assignedUserId) {
      params = params.set('assignedUserId', assignedUserId);
    }
    if (beforeTs) {
      params = params.set('beforeTs', beforeTs);
    }
    if (afterTs) {
      params = params.set('afterTs', afterTs);
    }
    if (name) {
      params = params.set('name', name);
    }
    if (description) {
      params = params.set('description', description);
    }

    return this.httpClient
      .get<GetPersonalEventsResult>(this.basePath, { params })
      .pipe(first());
  }

  public updateEvent(
    body: PatchPersonalEventRequest,
    id: number,
  ): Observable<PatchPersonalEventResult> {
    const path = `${this.basePath}/${id}`;

    return this.httpClient
      .patch<PatchPersonalEventResult>(path, body)
      .pipe(first());
  }
}
