import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetAllEventsResult } from '@models/getAllEventsResult';
import { GetEventByIdResult } from '@models/getEventByIdResult';
import { environment } from '../../../environments/environment';
import { EventParam } from '@models/event-api';

@Injectable({ providedIn: 'root' })
export class EventApiService {
  protected basePath = `${environment.baseUrl}/api/events/all`;

  constructor(protected httpClient: HttpClient) {}

  public getEvent(id: number): Observable<GetEventByIdResult> {
    return this.httpClient.get(
      `${this.basePath}/${encodeURIComponent(String(id))}`,
    );
  }

  public getEvents(eventParams: EventParam): Observable<GetAllEventsResult> {
    const params: any = Object.keys(eventParams).reduce((acc, curr) => {
      let paramValue = eventParams[curr as keyof EventParam];
      if (Array.isArray(paramValue)) {
        paramValue = paramValue.join(',');
      }
      //@ts-ignore
      acc[curr] = paramValue;
      return acc;
    }, {});

    return this.httpClient.get<GetAllEventsResult>(this.basePath, { params });
  }
}
