import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CreateGroupEventRequest,
  CreateGroupEventResult,
  GetGroupEventsRequest,
  GroupEventDeleteResult,
  GroupEventDto,
  GroupEventResult,
  GroupEventsResult,
  PatchGroupEventRequest,
  PatchGroupEventResult,
  UpdateGroupMembersRequest,
} from '@models/group-events-api';
import { first, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupEventsApiService {
  protected basePath = `${environment.baseUrl}/api/events/group`;

  constructor(private httpClient: HttpClient) {}

  getEvents({
    limit,
    page,
    sort,
    name,
    description,
    afterTs,
    beforeTs,
  }: GetGroupEventsRequest): Observable<GroupEventsResult> {
    let params = new HttpParams()
      .set('limit', limit)
      .set('page', page)
      .set('sort', sort);

    if (beforeTs) {
      params = params.set('beforeTs', beforeTs);
    }
    if (afterTs) {
      params = params.set('afterTs', afterTs);
    }
    if (name) {
      params = params.set('name', name);
    }
    if (description) {
      params = params.set('description', description);
    }

    return this.httpClient
      .get<GroupEventsResult>(this.basePath, { params })
      .pipe(first());
  }

  getEventById(id: number): Observable<GroupEventDto> {
    return this.httpClient.get<GroupEventResult>(`${this.basePath}/${id}`).pipe(
      first(),
      map((resp) => resp.event),
    );
  }

  deleteEvent(
    id: number,
    isDeleteAll?: boolean,
  ): Observable<GroupEventDeleteResult> {
    const params: Record<string, boolean> = {};
    if (isDeleteAll) {
      params['isDeleteAll'] = isDeleteAll;
    }
    return this.httpClient
      .delete(`${this.basePath}/${id}`, { params })
      .pipe(first());
  }

  updateEvent(
    id: number,
    body: PatchGroupEventRequest,
  ): Observable<GroupEventDto> {
    return this.httpClient
      .patch<PatchGroupEventResult>(`${this.basePath}/${id}`, body)
      .pipe(map((res) => res.event));
  }

  createEvent(body: CreateGroupEventRequest): Observable<GroupEventDto> {
    return this.httpClient
      .post<CreateGroupEventResult>(this.basePath, body)
      .pipe(
        first(),
        map((r) => r.event),
      );
  }

  addMemberToAssignedGroup(
    eventId: number,
    body: UpdateGroupMembersRequest,
  ): Observable<any> {
    return this.httpClient.post(`${this.basePath}/${eventId}/members`, body);
  }

  removeMemberFromAssignedGroup(
    eventId: number,
    employeeId: string,
  ): Observable<any> {
    const params: Record<string, string> = {
      employeeId,
    };
    return this.httpClient.delete(`${this.basePath}/${eventId}/members`, {
      params,
    });
  }
}
