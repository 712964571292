import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AbsenceEventDto,
  CreateAbsenceEventRequest,
  CreateAbsenceEventResponse,
  DeleteAbsenceEventResult,
  GetAbsenceEventRequest,
  GetAbsenceEventResultById,
  GetAbsenceEventsResult,
  PatchAbsenceEventRequest,
  PatchAbsenceEventResult,
} from '@models/absence';
import { first, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AbsenceEventApiService {
  protected basePath = `${environment.baseUrl}/api/events/absence`;

  constructor(private httpClient: HttpClient) {}

  public createEvent(
    body: CreateAbsenceEventRequest,
  ): Observable<CreateAbsenceEventResponse> {
    return this.httpClient
      .post<CreateAbsenceEventResponse>(this.basePath, body)
      .pipe(first());
  }

  public deleteEvent(id: number): Observable<DeleteAbsenceEventResult> {
    return this.httpClient.delete<DeleteAbsenceEventResult>(
      `${this.basePath}/${id}`,
    );
  }

  public getEventById(id: number): Observable<GetAbsenceEventResultById> {
    return this.httpClient
      .get<GetAbsenceEventResultById>(`${this.basePath}/${id}`)
      .pipe(first());
  }

  public getEvents({
    absenceType,
    afterTs,
    assignedUserId,
    beforeTs,
    name,
    description,
    limit,
    page,
    sort,
  }: GetAbsenceEventRequest): Observable<GetAbsenceEventsResult> {
    let params: any = new HttpParams()
      .set('limit', limit)
      .set('page', page)
      .set('sort', sort);

    if (absenceType) {
      params = params.set('absenceType', absenceType);
    }
    if (assignedUserId) {
      params = params.set('assignedUserId', assignedUserId);
    }
    if (beforeTs) {
      params = params.set('beforeTs', beforeTs);
    }
    if (afterTs) {
      params = params.set('afterTs', afterTs);
    }
    if (name) {
      params = params.set('name', name);
    }
    if (description) {
      params = params.set('description', description);
    }

    return this.httpClient
      .get<GetAbsenceEventsResult>(this.basePath, { params })
      .pipe(first());
  }

  public updateAbsenceEvent(
    body: PatchAbsenceEventRequest,
    id: number,
  ): Observable<AbsenceEventDto> {
    const path = `${this.basePath}/${id}`;

    return this.httpClient.patch<PatchAbsenceEventResult>(path, body).pipe(
      map((res) => res.event),
      first(),
    );
  }
}
